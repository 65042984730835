import ManualEditImage from 'assets/images/support/manual_edit_image.jpg';
import ManualEditBold from 'assets/images/support/manual_edit_bold.jpg';
import Configure from 'assets/images/support/configure.jpg';
import PageTypes from 'assets/images/support/pagetypes.jpg';


export const SUPPORT_ITEMS_FIRST_COLUMN = [
  {
    title: 'About data collection',
    questions: [
      {
        question: 'Do you record or access any of our student, staff or visitor data?',
        answer: 'No. We do not store any attributable or personal data.',
      },
      {
        question: 'Do you rent or sell any student, staff or visitor users data?',
        answer: 'No. We do not store any data at all about your student, staff or visitors.',
      },
    ],
  },
  {
    title: 'About the Educamp App',
    questions: [
      {
        question: 'Is Educamp app free?',
        answer: 'Yes. It will always be free to your student, staff or visitors. Universities, colleges an schools pay to build their own no code campus app.',
      },
      {
        question: 'Is Educamp app available for IOS and Android?',
        answer: 'Yes. It is available in both app stores. See the links at the top and bottom of this page',
      },
      {
        question: 'Will every Educamp user see every push message?',
        answer: 'Users have the option to manage notifications on thier own devices.',
      },
      {
        question: 'How often does the app update with my content?',
        answer: 'Some of the content may be cached on Users phones, some other types of content will be refreshed when Users tap to view the content. Cached content will also update when the User quits and comes back.',
      },
    ],
  },
  {
    title: 'About Images',
    questions: [
      {
        question: 'What is the spec for images in Image Messages?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images will be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide, content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for Home page images?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images will be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide, content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for PDFs I want to attach to a message?',
        answer: 'You can attach PDFs to Messages that guests can download. Please ensure your PDFs are as small in size as possible - and no larger than 400k (preferably much smaller). Turn off editing functions and keep the quality suitable for mobile screens.',
      },
    ],
  },
  {
    title: 'Paperless campus informatiom',
    questions: [
      {
        question: 'What is paperless campus information?',
        answer: `
        <p>We're just talking about information you disseminate about your campus, like emergency procedures, codes of conduct, health & safety - any information you would normally make available as PDFs or printed material required by staff and or students.</p>
        `,
      },
      {
        question: 'Can I include images in our campus information?',
        answer: `
        <p>Yes. You can add Sections and Subsections, and include an image to head up each one or within the text field.</p>
        <img src="${ManualEditImage}" alt="Edit Your Product manual App" />',
        `,
      },
      {
        question: 'Can I format the text, like Bold and itallic etc?',
        answer: `
        <p>Yes. It includes a fully formatted text editor.</p>
        <img src="${ManualEditBold}" alt="Format Images in your Product Manual App" />
      `,
      },
    ],
  },
];

export const SUPPORT_ITEMS_SECOND_COLUMN = [
  {
    title: 'Configuring Your Brand',
    questions: [
      {
        question: 'How do I add my University, College or School brand name and logo?',
        answer: `
        <p>You should be automatically taken to a page on Sign Up where you can add your logo, select your brand colours and font you would like to use.</p>
        <p>You can update this anytime by clicking on Home and selecting the Configure Brand button below the app preview.</p>
        <img src="${Configure}" alt="Configure your brand logo and colors" />
      `,
      },
      {
        question: 'Once I have Configured Brand - how do I add my campus app pages?',
        answer: `
        <p>Once you have Configured brand, you should be asked to add a campus.</p>
        <p>You can add your campus for a free period, you will be asked to upgrade to a monthly or annual subscription to continue.</p>
        `,
      },
    ],
  },
  {
    title: 'About Messaging',
    questions: [
      {
        question: 'What is the difference between Messaging and a Quick Message?',
        answer: `
        <p>A quick message is just a Push notification, with no content associated with it.</p>
        <p>A Quick message will be sent to everyone who has added your product as a favorite. It's really handy to communicate something quickly, obviously.</p>
        <p>A Message usually has an image, a headline, some text and maybe a PDF or YouTube video content associated with it.</p>
        <p>Use messages to showcase events, local partners, timetable / faculty updates and more.</p>

        `,
      },
      {
        question: 'Do I have to send a Push with a Message?',
        answer: 'You have the option to include a Push notfication if you wish.',
      },
      {
        question: 'What images and branding are included in the Push message.',
        answer: 'Where allowed by the platform (IOS/Android) we include our logo thumbnail and your message image.',
      },
    ],
  },
  {
    title: 'About adding content to your app',
    questions: [
      {
        question: 'How page Pages or Tabs can I add?',
        answer: 'As many as you wish. Our system will automatically add a More page to list any more than your 5th page.',
      },
      {
        question: 'What types of pages can I add?',
        answer: `
        <p>There are a number of different page types you can add.</p>
        <p><strong>Home</strong> - Every app must have a Home page (default). Add one or more images as a gallery (just add as many images as you wish).</p>
        <p><strong>Messages</strong> - (default) Contains your image messages.</p>
        <p><strong>Campus Info</strong> - Typically codes of conduct, emergency procedures, health & safety etc.</p>
        <p>Add language translations (subscription required)</p>
        <p><strong>Call</strong> - calls the number you right out of your app.</p>
        <p><strong>Rich Text Format</strong> - adds a dynamic page, with any content you wish (text and images).</p>
        <p><strong>URL website address</strong> - links direct to any page on any website - must be htts secure.</p>
        <p><strong>We are adding more page types all the time - these are in the pipeline</strong></p>
        <p>Social Media Feeds - just add your ID, we'll automatically add your social media feeds (you can use web URL page for now)</p>
        <p>Contacts directory - jadd a title, email and phone for any number of contacts (Reception, New Bookings, Housekeeping etc)</p>
        <img src="${PageTypes}" alt="Page types added to no code app" />
        `,
      },
    ],
  },
  {
    title: 'Admin on mobile and tablets',
    questions: [
      {
        question: 'Does the Admin section work on mobiles and tablets?',
        answer: 'Yes. The whole Admin has been designed to work on any connected device, including Mobile and Tablets.',
      },
      {
        question: 'Is there a specific app I need to download to manage Admin?',
        answer: 'No! This is not necessary as we have optimised the site for use on Mobiles and Tablets. However, you can download and save the icon to your device Home Screen providing you with easy access right on your mobile!',
      },
      {
        question: 'How do I add Admin to my iPhone for easy access?',
        answer: 'Open Safari and go to Manualpro.co.co. Scroll up until you see a bar appear at the bottom of your screen with some icons in it. You should see in the middle a square box with an arrow pointing up. Tap this, and scroll down until you see an option to Add to Home Screen with a square +. Tap Add in the top right. You are done!',
      },
      {
        question: 'How do I add Admin to my Android phone for easy access?',
        answer: 'Open Chrome and go to Manualpro.co. Tap the 3 dots icon top right, and select Add to Home screen, tap Add. You are done!',
      },
    ],
  },
];
