import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import Campus from 'assets/images/123/campus-signposting.png';
import Brand from 'assets/images/123/brand_your_university_app.png';
import Fish from 'assets/images/123/app_different_kettle_of_fish.png';
import Add from 'assets/images/123/add_your_campus_now.png';

import CaseItem from './case-item';


const CasesSection = React.memo(() => (
  <Box
    bgcolor="primary.light"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Easy as 1, 2, 3
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Create your campus app - no code required! Keep campus students, staff and vistors in the loop.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Brand}
            title="Build your campus app"
            subtitle="So simple, no code required"
            description={(
              <>
                Add your logo, pick your colours and choose your typeface. Make it look like you.
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Add}
            title="Add your content"
            subtitle="Wrap up everything in your brand"
            description={(
              <>
                Add key web pages, contact directory, local places and partners, all your social media feeds, messaging and much more ...
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Campus}
            title="On campus, everything they need"
            subtitle="Students and staff just scan a QR code"
            description={(
              <>
                Just scan the QR code provided and Educamp app turns completely into your own branded campus app. All modern phones can do this.
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Fish}
            title="It&apos;s a different kettle of fish"
            subtitle="We love fish, but this is different"
            description={(
              <>
                No coding required. Simply a great, fully branded campus app - with push messaging at your (and their) fingertips. No fish required!
              </>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


CasesSection.propTypes = {};

CasesSection.defaultProps = {};


export default CasesSection;
