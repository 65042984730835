export const MODAL_MESSAGE = {
  SIGN_UP_SUCCESS: 'We have sent you a registration confirmation link. Please check your email (and your junk folder - just in case) to finish registration. If you do not receive this please email us at hello@educamppro.com or contact support chat.',
  SEND_RESET_LINK_SUCCESS: 'We have sent you a reset link. Please check your email address.',
  UPDATE_EMAIL_SUCCESS: 'Thanks! Email has been updated! You will need to login with the new email on the next app reload',

  UPDATE_BRAND_SUCCESS: 'Your brand configuration has been saved.',
  UPDATE_ENTERPRISE_IMAGE_SUCCESS: 'Your enterprise home image has been saved.',

  PRODUCT_CREATE_SUCCESS: 'Thanks! Your campus has been successfully added!',
  PRODUCT_DELETE_SUCCESS: 'Your campus has been deleted.',
  PRODUCT_UPDATE_SUCCESS: 'Your campus has been updated',
  PRODUCT_UNLOCK_SUCCESS: 'Thanks! Your campus is active now!',
  PRODUCT_COPY_SUCCESS: 'Thanks! Your campus was duplicated!',
  SAVE_PRODUCTS_ORDER_SUCCESS: 'Thanks! Order of the campuses has been saved!',

  MANAGER_CREATE_SUCCESS: 'User has been successfully created! User will receive an email with registration link.',
  MANAGER_DELETE_SUCCESS: 'User has been deleted.',
  MANAGER_UPDATE_SUCCESS: 'User has been updated',

  MESSAGE_CREATE_SUCCESS: 'Thanks! Your message has been successfully added!',
  QUICK_MESSAGE_CREATE_SUCCESS: 'Thanks! A Quick Message has been sent to the users!',
  MESSAGE_DELETE_SUCCESS: 'Message has been deleted.',
  MESSAGE_UPDATE_SUCCESS: 'Message has been updated',
  MESSAGE_TAGS_UPDATE_SUCCESS: 'Thanks! Your message tags have been updated!',
  QUICK_MESSAGE_DELETE_SUCCESS: 'Quick Message has been deleted.',

  SUBSCRIPTION_ADD_3D_SECURE: 'To finish payment process please follow the link below:',
  SUBSCRIPTION_ADD_SUCCESS: 'Thanks! Your subscription has been successfully added!',
  SUBSCRIPTION_RENEW_SUCCESS: 'Thanks! Your subscription is active now!',
  SUBSCRIPTION_CANCEL_SUCCESS: 'Thanks! Your subscription is cancelled.',

  BANK_TRANSFER_ADD_SUCCESS: 'Thanks! Bank transfer has been successfully added!',
  BANK_TRANSFER_UPDATE_SUCCESS: 'Thanks! Bank transfer has been successfully updated!',
  BANK_TRANSFER_DELETE_SUCCESS: 'Thanks! Bank transfer has been successfully deleted!',

  SUPPORT_MESSAGE_SEND_SUCCESS: 'Thanks! Your message has been sent!',

  PAYMENT_METHOD_REMOVE_SUCCESS: 'Your card has been removed.',
  PAYMENT_METHOD_ADD_SUCCESS: 'Thanks! Your card has been added.',

  CREATE_ADMIN_SUCCESS: 'Thanks! Admin has been created!',
  DEMO_USER_CANNOT_ADD_MANAGERS: 'Demo users cannot add managers',
  DEMO_USER_CANNOT_SUBSCRIBE: 'Demo users cannot add subscriptions. Please register an account to proceed.',
  DEMO_USER_CANNOT_ADD_CARD: 'Demo users cannot add cards. Please register an account to proceed.',

  CREATE_PROFILE_SUCCESS: 'Thanks! Profile has been successfully created!',
  UPDATE_PROFILE_SUCCESS: 'Thanks! Profile has been updated!',
  RESET_PROFILE_PASSWORD_SUCCESS: 'Thanks! We have sent a password to the profile email.',
};
