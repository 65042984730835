const config = {
  firebase: {
    apiKey: 'AIzaSyDk4sHquNK3zw48R8AI9OOMXP2V1vZZszA',
    authDomain: 'educamppro-bf632.firebaseapp.com',
    databaseURL: 'https://educamppro-bf632.firebaseio.com',
    projectId: 'educamppro-bf632',
    storageBucket: 'educamppro-bf632.appspot.com',
    messagingSenderId: '1070912675700',
    appId: '1:1070912675700:web:1f8150acfab261e552d621',
    measurementId: 'G-GT5HQ34JPR',
  },
  stripePublicAPIKey: 'pk_live_QCfxYauYsBcAVEuKo82E1Ud400bX9KzwxD',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAgBlWt5homf78UBDVEHIOEeST_mcksvaU&libraries=places,geometry',
  },
};


export default config;
