import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import CurrencySelector from '@pro/web-common/components/currency-selector-home';

import { DEFAULT_CURRENCY_ID } from 'constants/currency';
import { PLAN_TYPE_SINGLE_ID, PLAN_TYPE_FREE_ID, PLAN_TYPE_ANNUAL_ID } from 'constants/subscriptions-config';

import PlanItem from './plan-item';


const PricingSection = React.memo(({ isSignIn }) => {
  const [currencyId, setCurrencyId] = useState(DEFAULT_CURRENCY_ID);

  return (
    <Box
      bgcolor="primary.additional"
      pt={8}
      pb={8}
      id="pricing"
    >
      <Container maxWidth="md">
        <Box
          textAlign="center"
          mb={3}
        >
          <Typography
            variant="h3"
          >
            Simple pricing.
          </Typography>
        </Box>

        <Box
          textAlign="center"
          mb={3}
        >
          <CurrencySelector
            currencyId={currencyId}
            onChange={setCurrencyId}
          />
        </Box>

        <Grid
          container
          justify="space-between"
          spacing={8}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <PlanItem
              title="Free TRIAL"
              features={[
                'One Campus',
                'Fully Branded',
                'Home Page Gallery',
                'Campus Information',
                'Multi Language',
                'Message Students & Staff',
                'Include Images/Video',
                'Accessibility Settings',
                'Social Media Feeds',
                'Local Partners',
                'Build Pages',
                'Contacts Directory',
              ]}
              buttonText="Sign up free"
              opacity={0.7}
              planId={PLAN_TYPE_FREE_ID}
              currencyId={currencyId}
              isSignIn={isSignIn}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <PlanItem
              title="Per MONTH"
              features={[
                'One Campus',
                'Fully Branded',
                'Home Page Gallery',
                'Campus Information',
                'Multi Language',
                'Message Students & Staff',
                'Include Images/Video',
                'Accessibility Settings',
                'Social Media Feeds',
                'Local Partners',
                'Build Pages',
                'Contacts Directory',
              ]}
              opacity={0.5}
              planId={PLAN_TYPE_SINGLE_ID}
              currencyId={currencyId}
              isSignIn={isSignIn}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <PlanItem
              title="Pay ANNUALLY"
              features={[
                'One Campus',
                'Fully Branded',
                'Home Page Gallery',
                'Campus Information',
                'Multi Language',
                'Message Students & Staff',
                'Include Images/Video',
                'Accessibility Settings',
                'Social Media Feeds',
                'Local Partners',
                'Build Pages',
                'Contacts Directory',
              ]}
              opacity={0.7}
              planId={PLAN_TYPE_ANNUAL_ID}
              currencyId={currencyId}
              isSignIn={isSignIn}
              period="per year"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


PricingSection.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
};

PricingSection.defaultProps = {};


export default PricingSection;
