import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { fade } from '@material-ui/core/styles/colorManipulator';

import {
  Box,
  Typography,
  Button,
} from '@material-ui/core';

import { CURRENCY_LIST } from 'constants/currency';
import { PLAN_TYPE_PRICES } from 'constants/subscriptions-config';


const PlanItem = React.memo(({ currencyId, planId, title, features, buttonText, opacity, isSignIn, period }) => {
  const planData = PLAN_TYPE_PRICES[planId];

  return (
    <Box textAlign="center">
      <Box
        bgcolor={fade('#FFF', opacity)}
        p={3}
        mb={2}
      >
        <Box mb={2}>
          <Typography
            variant="h5"
          >
            {title}
          </Typography>
        </Box>

        {features.map((feature, index) => (
          <Box
            mb={1 / 2}
            key={index} // eslint-disable-line react/no-array-index-key
          >
            <Typography>{feature}</Typography>
          </Box>
        ))}


        {planData && (
          <Box mt={2}>
            <Typography variant="h4">
              {CURRENCY_LIST[currencyId].sign}{planData[currencyId].price}
            </Typography>

            <Typography>{period}</Typography>

            {CURRENCY_LIST[currencyId].vat && (
              <Typography>plus {CURRENCY_LIST[currencyId].vat} VAT</Typography>
            )}
          </Box>
        )}
      </Box>

      {!isSignIn && (
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          to="/sign-up"
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
});


PlanItem.propTypes = {
  currencyId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string,
  opacity: PropTypes.number,
  isSignIn: PropTypes.bool.isRequired,
  period: PropTypes.string,
};

PlanItem.defaultProps = {
  opacity: 1,
  buttonText: 'Sign Up',
  period: 'per month',
};


export default PlanItem;
