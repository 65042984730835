import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  FormControl,
  Box, Collapse, IconButton,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

import { withFormik } from 'formik';

import { colorPropsSchema } from '@pro/web-common/utils/style';

import ColorPicker from '@pro/web-common/components/color-picker';
import Select from '@pro/web-common/components/select';
import InfoSection from '@pro/web-common/components/info-section';

import infoJson from 'constants/info.json';

import { typefaceValues } from 'constants/brand-config';
import {
  DEFAULT_MENU_BACKGROUND_COLOR,
  DEFAULT_MENU_FONT_COLOR,
  DEFAULT_MENU_TITLE_BG_COLOR,
  DEFAULT_MENU_TITLE_TEXT_COLOR,
  DEFAULT_MENU_ITEM_TEXT_COLOR,
} from './config';
import { styles } from './styles';


const MenuThemeConfigurator = React.memo(({ onChange, isSubItemsEnabled, backgroundColorLabel, titleColorLabel, ...formikProps }) => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formikProps;

  const classes = styles();

  const [isOpened, setIsOpened] = useState(false);

  const toggleEditor = useCallback(
    () => setIsOpened(!isOpened),
    [isOpened],
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr={2}>
          <Typography>Colours</Typography>
        </Box>

        <IconButton onClick={toggleEditor}>
          <EditIcon />
        </IconButton>
      </Box>

      <Collapse
        in={isOpened}
        mountOnEnter
        unmountOnExit
      >
        <Box className={classes.form}>
          <Grid
            container
            spacing={10}
          >
            <Grid
              item
              xs={4}
            >
              <Box mb={2}>
                <InfoSection infoMessage={infoJson.product.menu.font}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      label="Font / Typeface"
                      id="typeface"
                      name="typeface"
                      options={typefaceValues}
                      value={values.typeface}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      withFontFamily
                    />
                  </FormControl>
                </InfoSection>
              </Box>

              <InfoSection infoMessage={infoJson.product.menu.fontColour}>
                <ColorPicker
                  label="Font Colour"
                  color={values.fontColor}
                  onColorChange={(value) => setFieldValue('fontColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.product.menu.backgroundColour}>
                <ColorPicker
                  label="Background"
                  color={values.backgroundColor}
                  onColorChange={(value) => setFieldValue('backgroundColor', value)}
                />
              </InfoSection>
            </Grid>

            <Grid
              item
              xs={4}
            >
              <Box mr={5}>
                <InfoSection infoMessage={infoJson.product.menu.menuTitleBackgroundColor}>
                  <ColorPicker
                    label={backgroundColorLabel}
                    color={values.menuTitleBackgroundColor}
                    onColorChange={(value) => setFieldValue('menuTitleBackgroundColor', value)}
                  />
                </InfoSection>

                <InfoSection infoMessage={infoJson.product.menu.menuTitleTextColor}>
                  <ColorPicker
                    label={titleColorLabel}
                    color={values.menuTitleTextColor}
                    onColorChange={(value) => setFieldValue('menuTitleTextColor', value)}
                  />
                </InfoSection>

                {
                  isSubItemsEnabled && (
                    <InfoSection infoMessage={infoJson.product.menu.menuItemTextColor}>
                      <ColorPicker
                        label="Menu Item Text Colour"
                        color={values.menuItemTextColor}
                        onColorChange={(value) => setFieldValue('menuItemTextColor', value)}
                      />
                    </InfoSection>
                  )
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </>
  );
});

MenuThemeConfigurator.propTypes = {
  initialValues: PropTypes.shape({
    backgroundColor: colorPropsSchema,
    typeface: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isSubItemsEnabled: PropTypes.bool.isRequired,
  backgroundColorLabel: PropTypes.string.isRequired,
  titleColorLabel: PropTypes.string.isRequired,
};

MenuThemeConfigurator.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues, isSubItemsEnabled }) => {
    const {
      backgroundColor,
      fontColor,
      menuTitleBackgroundColor,
      menuTitleTextColor,
      menuItemTextColor,
      typeface,
    } = initialValues || {};

    const values = {
      backgroundColor: backgroundColor || DEFAULT_MENU_BACKGROUND_COLOR,
      fontColor: fontColor || DEFAULT_MENU_FONT_COLOR,
      menuTitleBackgroundColor: menuTitleBackgroundColor || DEFAULT_MENU_TITLE_BG_COLOR,
      menuTitleTextColor: menuTitleTextColor || DEFAULT_MENU_TITLE_TEXT_COLOR,
      ...(isSubItemsEnabled ? { menuItemTextColor: menuItemTextColor || DEFAULT_MENU_ITEM_TEXT_COLOR } : {}),
      typeface: typeface || 'default',
    };

    return values;
  },
})(MenuThemeConfigurator);
