export const UPGRADED_PLAN_FEATURES = [
  {
    id: '1',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited number of Users',
  },
  {
    id: '2',
    label: 'Multi Language Product Manuals',
    description: 'Description of Multi Language Campus info feature',
  },
  {
    id: '3',
    label: 'Enhanced Messaging',
    description: 'Description of Enhanced Messaging feature',
  },
  {
    id: '4',
    label: 'Search Terms/Tags',
    description: 'Description of Search Terms/Tags feature',
  },
];

export const PLAN_TYPE_FREE_ID = 'free';
export const PLAN_TYPE_SINGLE_ID = 'single';
export const PLAN_TYPE_ANNUAL_ID = 'annual';
export const PLAN_TYPE_BANK_TRANSFER = 'bankTransfer';

export const ACTIVE_SUBSCRIPTION_LIMIT = null;

export const PLAN_TYPE_PRICES = {
  [PLAN_TYPE_SINGLE_ID]: {
    EUR: {
      price: '45.00',
    },
    GBP: {
      price: '39.00',
    },
    USD: {
      price: '49.00',
    },
  },
  [PLAN_TYPE_ANNUAL_ID]: {
    EUR: {
      price: '290.00',
    },
    GBP: {
      price: '249.00',
    },
    USD: {
      price: '329.00',
    },
  },
};

export const PLAN_TYPES = [
  {
    id: PLAN_TYPE_FREE_ID,
    title: 'FREE',
    canAdd: false,
    features: [
      {
        id: 'products_count',
        label: '1 x Campus',
      },
      {
        id: 'users_count',
        label: '1 x User',
      },
      {
        id: 'versions_count',
        label: '1 x Language',
      },
      {
        id: 'messaging',
        label: 'Messaging',
      },
    ],
  },
  {
    id: PLAN_TYPE_SINGLE_ID,
    title: '1 Campus',
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Add new campus @${currencySign}${price} per month`,
    availableProducts: 1,
    features: [
      {
        id: 'products_count',
        label: '1 x Campus',
      },
      {
        id: 'users_count',
        label: 'Unlimited Users',
      },
      {
        id: 'versions_count',
        label: 'Multi Language Campus Info',
      },
      {
        id: 'messaging',
        label: 'Enhanced Messaging',
      },
      {
        id: 'search',
        label: 'Search Terms/Tags',
      },
    ],
  },
  {
    // TODO: UPDATE DESCRIPTION!
    id: PLAN_TYPE_ANNUAL_ID,
    title: 'Annual Pack',
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Pay annually @${currencySign}${price} per year`,
    availableProducts: 9999,
    features: [
      {
        id: 'products_count',
        label: 'Annual Subscription',
      },
      {
        id: 'users_count',
        label: 'Unlimited Users',
      },
      {
        id: 'versions_count',
        label: 'Multi Language Campus Info',
      },
      {
        id: 'messaging',
        label: 'Enhanced Messaging',
      },
      {
        id: 'search',
        label: 'Search Terms/Tags',
      },
    ],
  },
  {
    id: PLAN_TYPE_BANK_TRANSFER,
    title: 'Bank Transfer',
    canAdd: false,
  },
];

export const UPGRADE_PLAN_FEATURES = [
  {
    id: 'users',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited number of Users',
  },
  {
    id: 'versions',
    label: 'Multi Language Campus Info',
    description: 'Description of Multi Language Campus info feature',
  },
  {
    id: 'messaging',
    label: 'Enhanced Messaging',
    description: 'Description of Enhanced Messaging feature',
  },
  {
    id: 'search_tags',
    label: 'Search Terms/Tags',
    description: 'Description of Search Terms/Tags feature',
  },
];
