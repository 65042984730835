import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import MenuThemeConfigurator from '@pro/web-common/components/menu-theme-configurator';
import MenuTrackTraceConfigurator from '@pro/web-common/components/menu-track-trace-configurator';

import { isTestVersion } from 'constants/product-config';

import { styles } from './styles';


const MenuConfigurator = React.memo(({ onThemeChange, initialThemeValues, onTrackTraceChange, initialTrackTraceValues, isSubItemsEnabled, backgroundColorLabel, titleColorLabel }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <MenuThemeConfigurator
        initialValues={initialThemeValues}
        onChange={onThemeChange}
        isSubItemsEnabled={isSubItemsEnabled}
        backgroundColorLabel={backgroundColorLabel}
        titleColorLabel={titleColorLabel}
      />

      {!isTestVersion && (
        <MenuTrackTraceConfigurator
          initialValues={initialTrackTraceValues}
          onChange={onTrackTraceChange}
        />
      )}
    </Box>
  );
});

MenuConfigurator.propTypes = {
  initialThemeValues: PropTypes.shape({}).isRequired,
  initialTrackTraceValues: PropTypes.shape({}).isRequired,
  onThemeChange: PropTypes.func.isRequired,
  onTrackTraceChange: PropTypes.func.isRequired,
  isSubItemsEnabled: PropTypes.bool,
  backgroundColorLabel: PropTypes.string.isRequired,
  titleColorLabel: PropTypes.string.isRequired,
};

MenuConfigurator.defaultProps = {
  isSubItemsEnabled: true,
};


export default MenuConfigurator;
