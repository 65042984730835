import AppStoreIcon from 'assets/images/store-icons/appStore.png';
import PlayStoreIcon from 'assets/images/store-icons/playStore.png';


export const STORE_LINKS = [
  {
    id: 'appStore',
    href: 'https://apps.apple.com/us/app/educamp/id1499319590',
    icon: AppStoreIcon,
  },
  {
    id: 'playStore',
    href: 'https://play.google.com/store/apps/details?id=com.kappow.educamppro',
    icon: PlayStoreIcon,
  },
];

export const dynamicLink = 'https://educamppro.page.link';
export const websiteDomain = 'https://educamppro.com';
export const appStoreId = '1499319590';
export const iosBundleId = 'com.appsprout.educamppro';
export const apn = 'com.kappow.educamppro';
