export const DEFAULT_MENU_BACKGROUND_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
export const DEFAULT_MENU_FONT_COLOR = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};
export const DEFAULT_MENU_TITLE_BG_COLOR = {
  r: 84,
  g: 84,
  b: 84,
  a: 1,
};
export const DEFAULT_MENU_TITLE_TEXT_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
export const DEFAULT_MENU_ITEM_TEXT_COLOR = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};
