import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  Box,
  Collapse,
} from '@material-ui/core';

import { ProductIdContext, UserRoleContext } from '@pro/web-common/containers/providers';
import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';
import { getCurrencySymbol } from '@pro/web-common/utils/currency';

import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import SingleImageUploader from '@pro/web-common/components/single-image-uploader';
import CurrencyTextField from '@pro/web-common/components/currency-input';

import { styles } from './styles';


const MenuSubSectionContentEditor = React.memo(({ isOpened, image, html, price, currencyId, onChange }) => {
  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);
  const classes = styles();

  const [handleContentChange] = useDebouncedCallback((data) => {
    onChange({ html: data });
  }, 100);

  const currencySymbol = useMemo(() => (getCurrencySymbol(currencyId)), [currencyId]);

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.container}>
        <SingleImageUploader
          image={image}
          onImageDelete={() => onChange({ image: null })}
          onImageAdd={(fileData) => onChange({ image: fileData })}
        />

        <Box className={classes.editorContainer}>
          <CKEditorCustomized
            data={html}
            onChange={handleContentChange}
            imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
          />
        </Box>

        <Box className={classes.currencyContainer}>
          <CurrencyTextField
            label="Price"
            variant="outlined"
            value={price}
            currencySymbol={currencySymbol}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) => onChange({ price: value })}
          />
        </Box>
      </Box>
    </Collapse>
  );
});

MenuSubSectionContentEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  html: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  currencyId: PropTypes.string,
  price: PropTypes.string,
};

MenuSubSectionContentEditor.defaultProps = {
  image: null,
  html: '',
  currencyId: null,
  price: null,
};


export default MenuSubSectionContentEditor;
