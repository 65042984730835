import { currencyValues } from '@pro/web-common/utils/currency';


export const FOOTER_TEXT_LINE_1 = '© 2020 Kappow Limited. Registered Office, 71-75 Shelton Street, Covent Garden, London WC2H 9JQ. Registered in the UK No. 12299683.';
export const FOOTER_TEXT_LINE_2 = 'Payments are processed by Kappow Apps Ltd. No. 12465596.';
export const MANAGER_FORM_CHOOSE_ITEM_LABEL = 'Choose campuses';
export const MANUAL_PAGE_EDITOR_ADD_INFO_TEXT = 'Add information for this Campus';
export const MENU_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Menu for this Campus';
export const TIMETABLE_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Timetable for this Campus';
export const ALL_ITEMS_LABEL = 'All Products';
export const PRODUCT_CONFIGURATION_FINISH_CONFIG_TEXT = 'Please finish Campus configuration';
export const PRODUCT_CONFIGURATION_ITEM_NAME_LABEL = 'Campus Name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_PLACEHOLDER = 'Add Campus Name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_REQUIRED_ERROR = 'Add Campus name;';
export const PRODUCT_CONFIGURATION_PAGES_COUNT_ERROR = 'Add minimum four pages for your Campus;';
export const PRODUCT_FILTERS_SELECT_ITEM_LABEL = 'Select Campus';
export const PRODUCTS_LISTING_NO_DATA_TEXT = 'You have not added a campus yet';
export const PRODUCT_ITEM_COPY_TEXT = 'Duplicate Campus';
export const PRODUCT_ITEM_SUBSCRIBE_TEXT = 'Please subscribe to add more';
export const PRODUCT_ITEM_RESUME_ACCESS_TEXT = 'Resume campus access';
export const PRODUCT_PAGE_ADD_VERSIONS_TEXT = 'Add versions';
export const PRODUCT_PAGE_SUBSCRIBE_TO_ADD_VERSIONS_TEXT = 'Please subscribe to add language versions';
export const QUICK_MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added quick messages for the selected campus yet';
export const MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added messages for the selected campus yet';
export const RTF_PAGE_EDITOR_TITLE = 'Add text page for Campus';
export const ADMIN_PRODUCTS_TITLE = 'Campus';
export const ADMIN_PRODUCTS_ADD_PRODUCT_BUTTON_LABEL = 'Add Campus';
export const getAdminProductsAddMoreItemsText = (availableProducts) => `You can add ${availableProducts} more ${availableProducts > 1 ? 's' : ''}`;
export const ADMIN_PRODUCTS_ADD_SUBSCRIPTION_TEXT = 'Add subscription to get more features or add more campuses';
export const ADD_FIRST_ITEM_TITLE = 'Add your first Campus';
export const ADD_PRODUCT_TITLE = 'Add Campus';
export const ADD_PRODUCT_SUBTITLE = 'Add a campus + any support pages';
export const ADD_PRODUCT_ADD_NEW_PRODUCTS_TEXT = 'add new campuses';
export const ADMIN_HOME_TITLE = 'My Home';
export const ADMIN_HOME_SUBTITLE = 'Your campus is listed here with the message tags Users can use to filter messages you send them. Edit your campus pages or add message tags (subscription only).';
export const ADMIN_SUBSCRIPTIONS_TITLE = 'My Subscriptions';
export const ADMIN_SUBSCRIPTIONS_SUBTITLE = 'Manage your subscriptions. If you have a Free Trial - UPGRADE to get full features. Add a New Campus or subscribe Annually.';
export const ADMIN_SUBSCRIPTIONS_ADD_NEW_ITEM_BUTTON_LABEL = 'Add new campus';
export const ADMIN_SUBSCRIPTIONS_ITEMS_NAME = 'campuses';
export const ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL = 'Contact us for other types of packs';
export const ADMIN_USERS_TITLE = 'My Users';
export const ADMIN_USERS_SUBTITLE = 'Manage Campus users';
export const EDIT_PRODUCT_SUBTITLE = 'Campus + any support pages';
export const EDIT_PRODUCT_LIMITED_ACCESS_NOTE = 'NOTE: Campus is limited in access. You will not be able to save your updates!';
export const QUICK_MESSAGES_TITLE = 'Quick Messaging';
export const QUICK_MESSAGES_SUBTITLE = 'Manage quick messaging for your Campus.';
export const QUICK_MESSAGES_NO_ITEMS_NOTE = 'NOTE: You have not added a Campus yet or have limited access - contact support chat if this is unexpected.';
export const LOCAL_DIRECTORY_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Directories for Product';
export const PROFILE_HOME_TITLE = 'My Home';
export const PROFILE_HOME_SUBTITLE = 'All your products are listed here.';

export const DEFAULT_MANUAL_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default edu section title',
      html: '<p>Default edu section content</p>',
      image: null,
      subsections: [
        {
          id: 2,
          title: 'Default edu sub section title',
          html: '<p>Default edu sub section content</p>',
          image: null,
        },
      ],
    },
  ],
};
export const DEFAULT_MENU_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default menu title',
      html: '<p>Default menu content</p>',
      image: null,
      isVisible: true,
      subsections: [
        {
          id: 2,
          title: 'Default menu item title',
          html: '<p>Default menu item content</p>',
          image: null,
          currencyId: currencyValues[0].value,
          isVisible: true,
        },
      ],
    },
  ],
};

export const DEFAULT_TIMETABLE_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default Timetable Item - 9.00am',
      html: '<p>Default Timetable Item - 9.00am</p>',
      image: null,
      isVisible: true,
    },
  ],
};
