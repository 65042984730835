import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Box, Collapse, IconButton,
  Typography, TextField,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

import { withFormik } from 'formik';

import Checkbox from '@pro/web-common/components/checkbox';

import { styles } from './styles';


const MenuTrackTraceConfigurator = React.memo(({ onChange, ...formikProps }) => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formikProps;

  const classes = styles();

  const [isOpened, setIsOpened] = useState(false);

  const toggleEditor = useCallback(
    () => setIsOpened(!isOpened),
    [isOpened],
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr={2}>
          <Typography>Check In/Out</Typography>
        </Box>

        <IconButton onClick={toggleEditor}>
          <EditIcon />
        </IconButton>
      </Box>

      <Collapse
        in={isOpened}
        mountOnEnter
        unmountOnExit
      >
        <Box className={classes.form}>
          <Grid
            container
            spacing={10}
          >
            <Grid
              item
              xs={4}
            >
              <Box mb={2}>
                <Checkbox
                  id="enableTrackTrace"
                  name="enableTrackTrace"
                  label="Enable Contact Trace"
                  value={values.isTrackTraceChecked}
                  onChange={({ target: { checked } }) => setFieldValue('isTrackTraceChecked', checked)}
                />
              </Box>

              <Box mb={2}>
                <Checkbox
                  id="autoCheckout"
                  name="autoCheckout"
                  label="Check out automatically at end of day."
                  value={values.autoCheckout}
                  onChange={({ target: { checked } }) => setFieldValue('autoCheckout', checked)}
                />
              </Box>

              <Box mb={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="infoText"
                  label="Info text"
                  placeholder="Info text"
                  name="infoText"
                  value={values.infoText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </>
  );
});

MenuTrackTraceConfigurator.propTypes = {
  initialValues: PropTypes.shape({
    isTrackTraceChecked: PropTypes.bool,
    autoCheckout: PropTypes.bool,
    infoText: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

MenuTrackTraceConfigurator.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      isTrackTraceChecked = false,
      autoCheckout = true,
      infoText = '',
    } = initialValues || {};

    const values = {
      isTrackTraceChecked,
      autoCheckout,
      infoText,
    };

    return values;
  },
})(MenuTrackTraceConfigurator);
