import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
} from '@material-ui/core';

import Select from '@pro/web-common/components/select';
import { currencyValues } from '@pro/web-common/utils/currency';

import { styles } from './styles';


const MenuCurrencySelector = React.memo(({ currencyId, onChange }) => {
  const classes = styles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
    >
      <Select
        id="currencyId"
        name="currencyId"
        label="Select currency"
        options={currencyValues}
        value={currencyId}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </FormControl>
  );
});


MenuCurrencySelector.propTypes = {
  currencyId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

MenuCurrencySelector.defaultProps = {};


export default MenuCurrencySelector;
