import React from 'react';

import {
  Container,
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';

import UniversityImage from 'assets/images/app-screenshots/University-Campus-App-1.png';
import SchoolImage from 'assets/images/app-screenshots/School-Campus-App-2.png';
import CollegeImage from 'assets/images/app-screenshots/College-Campus-App-3.png';
import CodeImage from 'assets/images/app-screenshots/Code-Free-Campus-App-4.png';


const ScreenshotsSection = React.memo(() => (
  <Box
    bgcolor="primary.additional"
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={12}
          sm={3}
        >
          <img
            src={UniversityImage}
            alt="University Campus App"
          />
        </Grid>

        <Hidden only="xs">
          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={SchoolImage}
              alt="School app"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={CollegeImage}
              alt="Apps for colleges"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={CodeImage}
              alt="Code free campus app"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  </Box>
));


ScreenshotsSection.propTypes = {};

ScreenshotsSection.defaultProps = {};


export default ScreenshotsSection;
