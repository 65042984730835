import { ROLES } from '@pro/web-common/core/user/constants';


export const TOP_LINKS = [
  {
    id: 'home',
    label: 'My Home',
    to: '/admin-home',
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.MANAGER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    id: 'profileHome',
    label: 'My Home',
    to: '/profile-home',
    authorizedOnly: true,
    allowForRoles: [ROLES.PROFILE],
  },
  {
    id: 'users',
    label: 'My Users',
    to: '/admin-users',
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
    authorizedOnly: true,
  },
  {
    id: 'subscriptions',
    label: 'My Subscriptions',
    to: '/admin-subscriptions',
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
    authorizedOnly: true,
  },
  {
    id: 'messaging',
    label: 'Messaging',
    to: '/messages',
    authorizedOnly: true,
  },
  {
    id: 'quick_messaging',
    label: 'Quick Messaging',
    to: '/quick-messages',
    authorizedOnly: true,
  },
  {
    id: 'statistics',
    label: 'Statistics',
    to: '/stats',
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
    authorizedOnly: true,
  },
  {
    id: 'resources',
    label: 'Resources',
    to: '/resources',
    allowForRoles: [ROLES.OWNER, ROLES.MANAGER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    id: 'signUp',
    label: 'Sign up',
    to: '/sign-up',
    unauthorizedOnly: true,
  },
  {
    id: 'signIn',
    label: 'Log in',
    to: '/login',
    unauthorizedOnly: true,
  },
];
