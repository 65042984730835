import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  formControl: {
    width: '300px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));
