import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    zIndex: theme.zIndex.modal + 1,
  },
}));
