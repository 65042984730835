import React from 'react';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import SectionImage from 'assets/images/home/campus-uni-home.png';

import { styles } from './styles';


const BuildManualSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      pt={6}
      pb={4}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              Build your own <br />
              Campus app
            </Typography>

            <Box
              fontSize="body1.fontSize"
              className={classes.list}
            >
              <ul>
                <li>Create your own campus app</li>
                <li>Style with your logo, images, colors and typeface</li>
                <li>Add all your on campus information</li>
                <li>Contacts directory, social media feeds</li>
                <li>Facility updates, news, timetable changes and more</li>
                <li>Powerful push messaging communicate quickly</li>
                <li>Local amenities, partner offers and events</li>
                <li>Add specific app only content</li>
                <li>Update 24/7, 365 days a year</li>
              </ul>
            </Box>
            <Typography
              variant="h5"
            >
              Your campus app where your students, staff and visitors need it.<br />
              In their pockets.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

            <Box
              mt={4}
              textAlign="right"
            >
              <Typography
                gutterBottom
                variant="h4"
              >
                Why EducampPro.co?
              </Typography>

              <Box
                className={classes.list}
              >
                <Typography>
                  <Box
                    component="span"
                    className={classes.disc}
                  >
                    &#8226;
                  </Box>
                  Visitors, staff and students need access to information when they are on campus.
                </Typography>
                <Typography>
                  <Box
                    component="span"
                    className={classes.disc}
                  >
                    &#8226;
                  </Box>
                  It can be difficult to find on websites, partner sites, social media posts - even on posters put up around campus.
                </Typography>
                <Typography>
                  <Box
                    component="span"
                    className={classes.disc}
                  >
                    &#8226;
                  </Box>
                  Unrivalled support 24hrs a day, 365 days a year. Simple pricing and no contract.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Box textAlign="center">
              <Typography
                gutterBottom
                variant="h5"
              >
                Make it all available in one app, in one place, in their pockets.
              </Typography>


            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


BuildManualSection.propTypes = {};

BuildManualSection.defaultProps = {};


export default BuildManualSection;
