import { generateId } from '@pro/web-common/utils';
import { currencyValues } from '@pro/web-common/utils/currency';


export const getSectionDefaultProps = () => ({
  id: generateId(),
  title: '',
  html: '',
  image: null,
  isVisible: true,
});

export const getSubSectionDefaultProps = () => ({
  id: generateId(),
  title: '',
  html: '',
  image: null,
  isVisible: true,
});
