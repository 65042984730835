import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Container, Grid, Typography, Box, Button } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import userActions from '@pro/web-common/core/user/actions';

import { styles } from './styles';


const DemoIntro = React.memo(({ signOut }) => {
  const classes = styles();

  const onSignUp = useCallback((event) => {
    event.preventDefault();
    signOut({ withRedirectToSignUp: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title="Demo Home Page Title"
        subtitle="Sub header which takes 2 columns Sub header which takes 2 columns"
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="First column title"
            subtitle="First column subtitle"
          />

          <Box className={classes.container}>
            <iframe
              title="youtube video"
              src="https://www.youtube.com/embed/AMMzY4TT3i4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="Second column title"
            subtitle="Second column subtitle"
          />

          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
              ml={2}
              mr={2}
            >
              <Button
                component={NavLink}
                color="inherit"
                variant="outlined"
                to="/configure-brand"
              >
                Demo Admin
              </Button>
            </Box>

            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

DemoIntro.propTypes = {
  signOut: PropTypes.func.isRequired,
};

DemoIntro.defaultProps = {};

const mapDispatchToProps = {
  signOut: userActions.signOut,
};


export default connect(null, mapDispatchToProps)(DemoIntro);
