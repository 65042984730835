import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Link,
} from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';

import SectionImage from 'assets/images/home/campus_app_students.jpg';

import { styles } from './styles';


const StartNowSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      bgcolor="primary.light"
      pt={10}
      pb={10}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h2"
            >
              Start now.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h1"
              >
                Student, staff &amp; <br />visitor campus app.
              </Typography>
            </Box>

            <Typography>
              With hundreds if not thousands of students, staff and visitors on campus, it can be hard to make sure everything they need is at their fingertips.<br />
              Emergency procedures, facility updates, local partners, maps, timetable changes ... <br />
              Wrap all your digital content, add messaging and put it all in one place - right on their mobile phones.
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                Educamp - The Campus App.
              </Box>
            </Typography>

            <Box
              mt={3}
              mb={[4, 0]}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                to="#pricing"
                smooth
                offset={-64}
                component={NavHashLink}
              >
                Pick A Plan
              </Button>

              <Typography component="span">
                FREE trial
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

            <Box mt={4}>
              {STORE_LINKS.map(({ id, href, icon }) => (
                <Link
                  key={id}
                  href={href}
                  className={classes.storeLink}
                >
                  <img
                    src={icon}
                    alt={id}
                  />
                </Link>
              ))}
            </Box>
            <Box
              mt={1}
              display="block"
              component="span"
              fontWeight="fontWeightBold"
            >
              Educamp app is free in the App and Play stores.
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


StartNowSection.propTypes = {};

StartNowSection.defaultProps = {};


export default StartNowSection;
