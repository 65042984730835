import React from 'react';
import { Helmet } from 'react-helmet';


export default {
  default: (
    <Helmet
      defaultTitle="EducampPro"
    >
      <meta name="keywords" content="student, university, campus, faculty, college, documentation, menu, visit, staff, teaching, course, communication, app" />
      <meta name="description" content="Improve campus communications for students, faculty staff and visitors with custom branded app. Visitors read QR code, contactless technology services for campus. Communicate with in app messaging." />
    </Helmet>
  ),
  home: (
    <Helmet>
      <title>Home Helmet Title</title>
      <meta name="description" content="Home Helmet description" />
    </Helmet>
  ),
  signUp: (
    <Helmet>
      <title>SignUp Helmet Title</title>
      <meta name="description" content="SignUp Helmet description" />
    </Helmet>
  ),
  logIn: (
    <Helmet>
      <title>Login Helmet Title</title>
      <meta name="description" content="Login Helmet description" />
    </Helmet>
  ),
  forgotPassword: (
    <Helmet>
      <title>forgotPassword Helmet Title</title>
      <meta name="description" content="forgotPassword Helmet description" />
    </Helmet>
  ),
  aboutUs: (
    <Helmet>
      <title>About Helmet Title</title>
      <meta name="description" content="About Helmet description" />
    </Helmet>
  ),
  support: (
    <Helmet>
      <title>Support Helmet Title</title>
      <meta name="description" content="Support Helmet description" />
    </Helmet>
  ),
  privacy: (
    <Helmet>
      <title>Privacy Helmet Title</title>
      <meta name="description" content="Privacy Helmet description" />
    </Helmet>
  ),
  terms: (
    <Helmet>
      <title>Terms Helmet Title</title>
      <meta name="description" content="Terms Helmet description" />
    </Helmet>
  ),
  gdpr: (
    <Helmet>
      <title>GDPR Helmet Title</title>
      <meta name="description" content="GDPR Helmet description" />
    </Helmet>
  ),
};
